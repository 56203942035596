<template>
  <div class="d-flex justify-center" cols="12" id="ExamConfirmation">
    <div class="justify-center  d-flex flex-column">
      <div class="d-flex flex-column justify-space-between align-center">
          <v-img :lazy-src="require('@/assets/images/placeholder.png')" width="350" class="my-3 mx-auto" :src="examToDoState.endImage"></v-img>
      </div>
      <div>
        <span class="text-center">
          {{examToDoState.endText}}
        </span>
      </div>
      <div>
        <v-dialog
          v-model="reviewDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="reviewDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>بازبینی: {{this.fullExam ? this.fullExam.name : ''}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="saveExamChanges()">
                  <v-icon dark left>
                    mdi-content-save
                  </v-icon>
                  ذخیره
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="question in fullExam.questions"
                :key="question.id">
                <v-expansion-panel-header>
                  {{question.number}}. {{question.title}}
                  <template v-slot:actions>
                    <v-icon color="green" v-if="question.selectedId">
                      mdi-arrow-up-drop-circle
                    </v-icon>
                    <v-icon color="error" v-else>
                      mdi-arrow-up-drop-circle
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-flex col="12" v-show="question.type === 'NORMAL'">
                    <v-radio-group
                      v-model="question.selectedItemId">
                      <v-row dense shrink>
                        <v-col cols="12"
                          v-for="item in question.items" :key="item.id"
                          class="pa-3 my-1" :class="{
                            'rectangle-selected': item.id === question.selectedItemId,
                            'rectangle': item.id !== question.selectedItemId}">
                          <v-radio
                            :label="item.title"
                            :value="item.id">
                          </v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-flex>
                  <v-flex v-show="question.type === 'LIKERT'">
                    <v-slider v-model="question.selectedItemId"
                      :thumb-size="36" thumb-label="always"
                      ticks="always" tick-size="4"
                      :min="question.likertItem ? question.likertItem.startFrom : undefined"
                      :max="question.likertItem ? question.likertItem.endTo : undefined"/>
                  </v-flex>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-dialog>
      </div>
      <v-footer fixed app color="white" paddless class="my-2">
        <v-container fluid>
          <v-row>
            <v-col cols="6" class="pa-2">
              <v-btn block x-large color="light-blue lighten-1 white--text" @click="submitExam()">
                ارسال
              </v-btn>
            </v-col>
            <v-col cols="6" class="pa-2">
              <v-btn block x-large color="gray lighten-5 black--text" @click="reviewExam()">
                بازبینی
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ExamConfirmation',
  data: () => ({
    code: undefined,
    reviewDialog: false,
    fullExam: {
      'id': '',
      'code': '',
      'name': '',
      'userDescription': '',
      'videoManualUrl': '',
      'origin': '',
      'optionType': '',
      'categoryId': '',
      'categoryName': '',
      'examFavoriteId': '',
      'examAssignId': '',
      'examAssignStatus': '',
      'questions': [
        {
          'id': '',
          'number': '',
          'title': '',
          'examAssignId': '',
          'selectedId': '',
          'selectedItemId': '',
          'items': [
            {
              'id': '',
              'createDate': '',
              'updateDate': '',
              'deleted': '',
              'version': '',
              'number': '',
              'title': '',
              'score': '',
              'questionId': ''
            }
          ]
        }
      ]
    }
  }),
  computed: {
    examToDoState: {
      get () {
        return this.$store.getters['exam/getExamToDo']
      },
      set (val) {
        this.$store.commit('exam/updateExamToDo', val)
      }
    }
  },
  mounted () {
    this.code = this.$route.params.code
  },
  methods: {
    submitExam () {
      this.$axios.post('exam/todo/' + this.code + '/confirm')
      .then(() => {
        this.examToDoState.examAssignStatus = 'ANSWERED'
        if (this.examToDoState.type === 'PUBLIC') {
          this.$router.push({ name: 'ExamResult' })
        } else {
          this.$router.push({ name: 'ExamDone' })
        }
      })
      .catch((error) => {
        console.error(error)
        this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
      })
    },
    reviewExam () {
      this.$axios.get('exam/todo/' + this.code + '/full')
      .then(response => {
        var data = response.data
        data.questions.sort(function(a,b){return a.number - b.number})
        data.questions.forEach(q => q.items.sort(function(a,b){return a.number - b.number}));

        this.fullExam = data
        this.reviewDialog = true
      })
      .catch( error => {
        console.error(error)
        this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
      })
    },
    saveExamChanges () {
      this.$axios.post('exam/todo/' + this.code + '/full', this.fullExam)
      .then(() => {
        this.reviewDialog = false
      })
      .catch((error) => {
        console.error(error)
        this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
      })
    }
  }
}
</script>
<style scoped>
  .rectangle {
    border-radius: 12px;
    border: solid 1px #f6f6f8;
    background-color: #ffffff;
  }
  .rectangle-selected {
    border-radius: 12px;
    background-color: #f0f7ff;
  }
</style>
